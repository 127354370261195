import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import { Suspense, lazy, StrictMode } from "react";
import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { PersistGate } from "redux-persist/integration/react";

//imports from this project:
import { ColorModeContextProvider } from "./theme/ColorModeContext";
import { SnackbarProvider } from "./components/Snackbar";
import TitleContextProvider from "./contexts/TitleContext";
import "./index.css";
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import { store, persistor } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary fallback="">
    <Suspense fallback={<div style={{ transform: "scale(1:555)" }} />}>
      <TitleContextProvider>
        <HashRouter>
          <SnackbarProvider>
            <ColorModeContextProvider>
              <Provider store={store}>
                <Toaster />
                <PersistGate persistor={persistor}>
                  <App />
                </PersistGate>
                <CssBaseline />
              </Provider>
            </ColorModeContextProvider>
          </SnackbarProvider>
        </HashRouter>
      </TitleContextProvider>
    </Suspense>
  </ErrorBoundary>
);
