// //store.js without redux persist:
// // ** Toolkit imports
// import { configureStore } from "@reduxjs/toolkit";
// // ** Reducers
// import userReducer from "./userSlice";

// export const store = configureStore({
//   reducer: {
//     user: userReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });

//store.js with redux persist:
// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";
// ** Reducers
import userReducer from "./userSlice";
//For persisting state:
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const rootReducer = combineReducers({
  user: userReducer,
});
const persistConfig = {
  key: "root",
  //version: 1,
  storage: storage,
  whitelist: ["user"],
  //whitelist: [],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };
