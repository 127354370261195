import React, { useContext } from "react";
import { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Stack from "@mui/material/Stack";
import AppsIcon from "@mui/icons-material/Apps";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, ButtonBase } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LightMode from "@mui/icons-material/LightMode";
import DarkMode from "@mui/icons-material/DarkMode";
import { ColorModeContext } from "../theme/ColorModeContext";
import DrawerMenu from "./DrawerMenu";
import Avatar from "@mui/material/Avatar";
import logoImage from "../assets/images/logo.png";
import { Outlet } from "react-router-dom";
import logo from "../assets/images/logo.png";
import internshipLogo from "../assets/images/internshipLogo.png";
import { blue, grey, indigo } from "@mui/material/colors";
import ProfilePopover from "./popovers/ProfilePopover";
import ServicePopover from "./popovers/ServicePopover";
import NotificationsPopover from "./popovers/NotificationsPopover";
import LanguagePopover from "./popovers/LanguagePopover";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import { TitleContext } from "../contexts/TitleContext";
import { H3, Small, H6, H5 } from "../components/Typography";
import { isBrowser, isMobile } from "react-device-detect";
import { Card } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { store } from ".././store";
import { useNavigate } from "react-router-dom";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
//
import { PORTAL_NAMES } from "../constants/constants";
import sidebarLogo from "../assets/images/swayam_logo.png";
import ministryLogo from "../assets/images/ministryLogo.png";
const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  // display: 'flex',
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Footer = styled("div", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: "100%",
  position: "fixed",
  top: "unset",
  bottom: 0,
  color: "white",
  backgroundColor: "black",
  textAlign: "center",
  size: "8px",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function PersistentDrawerLeft() {
  const { title } = useContext(TitleContext);
  const theme = useTheme();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  // const [username, setUsername] = React.useState(
  //   store.getState()?.user?.data?.data?.username
  // );
  const [rolename, setRolename] = React.useState("");
  const [dark, setDark] = React.useState(false);
  const { toggleColorMode } = React.useContext(ColorModeContext);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(store.getState().user.status);
    // if (store.getState().user.status === "success") {
    //   setUsername(user.data.data.userdetails.user.userName);
    //   setRolename(user.data.data.userdetails.roleMap[0].roleName);
    // } else {
    //   navigate("/");
    // }
  }, []);

  const date = new Date();
  // const [title, setTitle] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openE1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDarkMode = () => {
    setDark(!dark);
    toggleColorMode();
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{ mr: 2, ...(!open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <IconButton sx={{ mr: 2, ...(!open && { display: "none" }) }} onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
          <Typography sx={{ fontSize: 20, fontWeight: 700, color: "white" }}>
            {/* Employees Health Scheme  */}
            {title}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Divider orientation="vertical" flexItem sx={{ m: 1 }} />
          <Box
            sx={{
              display: {
                //xs: "none",
                md: "flex",
                ml: 20,
              },
            }}
          >
            <ProfilePopover />
          </Box>

          {/* <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              // aria-controls={mobileMenuId}
              aria-haspopup="true"
              // onClick={handleMobileMenuOpen}
              onClick={handleClick}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openE1}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu> */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          //backgroundColor: "blue",
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader
          sx={{
            height: 40, //55
            backgroundColor: "#E3F2FD",
            alignItems: "center",
            //display: "flex",
          }}
        >
          <Stack
            direction="row"
            justifyContent={"center"}
            display="flex"
            alignItems={"center"}
            
          >
            <Typography
              sx={{
                // pt: 2,
                float: "left",
                paddingRight: "10px",
                // paddingTop: "17px",
              }}
            >
              {/* <WorkOutlineIcon sx={{ color: "#2169b3", fontSize: 30 }} /> */}
              <img
                src={sidebarLogo}
                width="90px"
                height="60px"
                style={{
                  //border: "1px solid black",
                  borderRadius: "10px",
                }}
              />
              {/* <img src={internshipLogo} width="200px" height={"60px"} /> */}
            </Typography>
            <Typography
              sx={{
                // pt: 2,
                float: "right",
                color: "#2169b3",
                // paddingRight: "20px",
                // paddingBottom: "4px",
              }}
            >
              <H3 lineHeight={1.2}>{PORTAL_NAMES.PORTAL_NAME}</H3>
              {/* <small lineHeight={1.2}>{PORTAL_NAMES.PORTAL_NAME}</small> */}
            </Typography>
          </Stack>
        </DrawerHeader>
        <Divider />

        <Stack
          direction="row"
          sx={{ backgroundColor: theme.palette.primary[600] }}
        >
          <Typography sx={{ p: 2, float: "left" }}>
            <AccountCircle sx={{ fontSize: 40 }} />
          </Typography>
          <Typography sx={{ pt: 2, float: "left" }}>
            {/* <H5>{username}</H5> */}
            {/* <small lineHeight={1.2}>{rolename}</small> */}
            {/* <br></br> */}
            {/* <small lineHeight={1.2}>Last Login Time : {formatDate(date)}</small> */}
            <H5>Last Login Time :</H5>
            {formatDate(date)}
          </Typography>
        </Stack>
        {/* <Stack direction="row">
        <ButtonBase disableRipple  >
          <AccountCircle />
        <Box sx={{ml:1}}>
          <Typography sx={{fontSize:12, textAlign:'left'}}>Anil K Jain</Typography>
          <Typography sx={{fontSize:10, textAlign:'left',mt:-0.5}}>Mitra</Typography>
        </Box>
        
      </ButtonBase>

      </Stack> */}

        <DrawerMenu />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
      <Footer position="fixed" open={open}>
        {/* <Typography noWrap component="div">
          
          &copy; Site maintained by KPMG Advisory Services Pvt. Ltd.
        </Typography> */}
        <Typography
          variant="body2"
          //variant="body1"
          color="text.secondary"
          align="center"
        >
          {"Copyright © "}
          {/* <Link color="inherit"  */}
          {/* // href="https://www./" target="_blank" */}
          {/* > */}
          Site Maintained By KPMG Advisory Services Pvt. Ltd.
          {/* </Link> */} {new Date().getFullYear()}
          {"."}
        </Typography>
      </Footer>
    </Box>
  );
}
