import React, { useContext } from "react";
import {
  Badge,
  Box,
  ButtonBase,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import FlexBox from "../../components/FlexBox";
import { H6, Small, Tiny } from "../../components/Typography";
// import UkoAvatar from "components/UkoAvatar"
// import useAuth from "hooks/useAuth"
import { Fragment, useRef, useState } from "react";
// import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
import { persistor, store } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { logout } from "../../store/userSlice";

// styled components
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.secondary.light
        : theme.palette.divider,
  },
}));

const ProfilePopover = () => {
  const [username, setUsername] = React.useState("User Name");
  const [rolename, setRolename] = React.useState("Role");
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  // const { logout, user } = useAuth()
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    // if (store.getState().user.status === "success") {
    //   setUsername(user.data.data.userdetails.user.userName);
    //   setRolename(user.data.data.userdetails.roleMap[0].roleName);
    // } else {
    //   navigate("/");
    // }
  }, []);
  const handleMenuItem = (path) => {
    navigate(path);
    setOpen(false);
  };
  const dispatch = useDispatch();
  return (
    <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <AccountCircle />
        <Box sx={{ ml: 1 }}>
          <Typography sx={{ fontSize: 12, textAlign: "left" }}>
            User Profile{" "}
          </Typography>
          {/* <Typography sx={{fontSize:12, textAlign:'left'}}>username</Typography>
          <Typography sx={{fontSize:10, textAlign:'left',mt:-0.5}}>role</Typography> */}
        </Box>
      </ButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        // title={
        //   <FlexBox alignItems="center">
        //     {/* <UkoAvatar
        //       src={user?.avatar || "/static/avatar/001-man.svg"}
        //       sx={{ width: 35, height: 35 }}
        //     /> */}
        //     <AccountCircle sx={{ width: 35, height: 35, color: "grey" }} />
        //     {/* <Avatar sx={{ width: 35, height: 35 }}><AccountCircle /></Avatar> */}
        //     <Box ml={1}>
        //       <H6>{username}</H6>
        //       <Tiny display="block" fontWeight={500} color="text.disabled">
        //         {rolename}
        //       </Tiny>
        //     </Box>
        //   </FlexBox>
        // }
      >
        <Box pt={1}>
          {/* <StyledSmall onClick={() => handleMenuItem("/profile")}>
            Set Status
          </StyledSmall> */}

          {/* <StyledSmall onClick={() => handleMenuItem("/profile")}>
            Profile & Account
          </StyledSmall>

          <Divider sx={{ my: 1 }} /> */}

          <StyledSmall
            onClick={() => {
              navigate("/");
              // dispatch(logout());
              // persistor.purge();
              localStorage.clear();
            }}
          >
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
