import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Certificate1 from "../../assets/images/Certificate1.png";
import Certificate2 from "../../assets/images/Certificate2.png";
import Certificate3 from "../../assets/images/Certificate3.png";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../components/Snackbar";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from 'react-router-dom';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "black",
            },
            "&:hover fieldset": {
              borderColor: "#1976d2",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#1976d2",
            },
          },
        },
      },
    },
  },
});

const certificates = [
  {
    applicationId: 65,
    imageUrl: Certificate1,
    internshipRole: 'Udemy Java Course Certificate',
    companyName: 'Udemy',
    location: 'Bangalore',
  },
  {
    applicationId: 65,
    imageUrl: Certificate2,
    internshipRole: 'Microsoft AI Training Certificate',
    companyName: 'Microsoft',
    location: 'Chennai',
  },
  {
    applicationId: 65,
    imageUrl: Certificate3,
    internshipRole: 'LinkedIn C++ Course Certificate',
    companyName: 'Google',
    location: 'Mumbai',
  },
  
];

const ViewCertificates = () => {
 const navigate = useNavigate();
 const location = useLocation();
  const [open, setOpen] = useState(false); 
  const [reportData, setReportData] = useState([]);
  const { format } = require("date-fns");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(''); 
  
  // const studentId = useSelector((state) => state?.user?.data?.data?.studentId);
  const { showSnackbar } = useSnackbar();

  const studentId = location.state.id;

  useEffect(() => {
    fetchMyApplications();
  }, [studentId]);

  const fetchMyApplications = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_HOST_API_KEY}/api/fetchApplicationsByStudentId/${studentId}`
      )
      .then((response) => {
        if (response.data && response.data.status) {
          // Filter the data for statusId == 5, then map it to the required format
          const apiData = response.data.result
            .filter((item) => item.status.statusId === 5)  // Only include items where statusId is 5
            .map((item, index) => ({
              // Transform the filtered data
              applicationNumber: `${item.orderId}/${item.applicationId}`,
              studentName: item.studentId.studentFirstName,
              applicationId: item.applicationId,
              orderId: item.orderId,
              location: item.location,
              companyName: item.providerId.providerName,
              internshipRole: item.itemId.itemName,
              statusName: item.status.statusName,
              statusId: item.status.statusId,
              createdOn: item.createdOn
                ? format(new Date(item.createdOn), "dd-MM-yyyy hh:mm a")
                : "",
              lastUpdBy: item.updatedBy,
              lastUpdOn: item.updatedOn,
              imageUrl: Certificate2,
            }));
  
            const combinedData = [...apiData, ...certificates];

            setReportData(combinedData);   
        } else {
          showSnackbar("Error fetching data", "error");
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        showSnackbar("Error fetching data", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  

  console.log(reportData)

  const handleShareClick = () => {
    setOpen(true); 
  };

  const handleClose = () => {
    setOpen(false); 
    setEmail(''); 
  };

  const handleSubmit = () => {
    console.log("Email ID:", email); 
    setOpen(false); 
  };
  
  const handleViewCertificate = (applicationId) => {
    navigate(`/certification/${applicationId}`);
  };
  

  return (
    <>
      <Card sx={{ my: 2 }} elevation={3}>
        <ThemeProvider theme={theme}>
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "primary.main",
                borderBottom: "1px solid black",
              }}
            >
              Certifications
            </Typography>

            <Box p={2}>
            {(reportData.length == 0 && !loading) ? (
              <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
              No certificates found.
            </Typography>
 
) : (

  reportData.map((certificate) => (
    <Card key={certificate.applicationId} sx={{ mt: 2, border: "1px solid black" }}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <img
              src={certificate.imageUrl}
              alt="Certificate"
              style={{ width: '100%', height: 'auto', borderRadius: 8 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h6"><b>{certificate.internshipRole}</b></Typography>
            <Typography variant="subtitle1" sx={{ color: 'grey', mt: 1 }}>
              By: {certificate.companyName}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
              {certificate.location}
            </Typography>
            <Box>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={() => handleViewCertificate(certificate.applicationId)}
              >
                View And Download
              </Button>
              <Button variant="outlined" onClick={handleShareClick}>Share</Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ))
  
)}

            </Box>
          </CardContent>

          {/* Dialog for Sharing Certificate */}
          <Dialog open={open} onClose={handleClose}>
            {/* <DialogTitle>Share Details</DialogTitle> */}
            <DialogContent>
            <Typography variant="h6" sx={{ mb: 1 }}>
                         <b>Share Details</b>
                        </Typography>
              <TextField
                label="Enter Email ID"
                fullWidth
                size="small"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mx: 1 }}>
        Submit
      </Button>
      <Button onClick={handleClose} variant="outlined" color="primary" sx={{ mx: 1 }}>
        Cancel
      </Button>
    </Box>

            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </Card>
    </>
  );
};

export default ViewCertificates;
