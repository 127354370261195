// import DashboardIcon from "@mui/icons-material/Dashboard";
// import PersonIcon from "@mui/icons-material/Person";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { store } from ".././store";
import { useSelector } from "react-redux";
import * as MuiIcons from "@mui/icons-material";
//
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { hasChildren } from "../utils/MenuUtils";
import { useSnackbar } from "../components/Snackbar";

export default function DrawerMenu() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [menu, setMenu] = useState([]);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  let myObject = [
    // {
    //   icon: MuiIcons["Dashboard"],
    //   menuName: "Dashboard",
    //   link: "/home",
    // },
  ];

  useEffect(() => {
    // if (store.getState().user.status === "success") {
    //   console.log("subMenuList", user.data.data.userdetails.subMenuList);
    //   console.log(Object.keys(user.data.data.userdetails.menuList).length);
    // } else {
    //   navigate("/");
    // }

    fillMenu();
    const selectedMenu = menu.find(
      (item) => item.menuCode === location.pathname
    );
    setSelectedItem(selectedMenu);
  }, []);

  const fillMenu = async () => {
    //if (store.getState().user.status === "success") {
    // const userdetails = user.data.data.userdetails;
    // let i;
    // for (i = 0; i < Object.keys(userdetails.menuList).length; i++) {
    //   myObject.push({
    //     icon: MuiIcons[userdetails.menuList[i].icon],
    //     menuName: userdetails.menuList[i].menuName,
    //     link: userdetails.menuList[i].link,
    //     items: userdetails.subMenuList.filter(
    //       (x) => x.parentMenuId === userdetails.menuList[i].menuId
    //     ),
    //   });
    // }

    let userdetails = {};
    console.log(store.getState().user);
    const roles = user?.data?.data?.roles;
    if (roles?.length === 1) {
      switch (roles[0]) {
        case "ROLE_STUDENT":
          userdetails = {
            menuList: [
              // {
              //   icon: "Dashboard",
              //   menuId: 5,
              //   link: "/student-dashboard",
              //   parentMenuId: null,
              //   menuName: "Dashboard",
              //   menuCode: null,
              // },
              {
                icon: "AccountBox",
                menuId: 3,
                link: "/profile-data",
                parentMenuId: null,
                menuName: "Profile",
                menuCode: null,
              },
              {
                icon: "WorkOutlined",
                menuId: 1,
                link: "/internship-opportunities",
                parentMenuId: null,
                menuName: "Find Internship Opportunities",
                menuCode: null,
              },
              {
                icon: "Fax",
                menuId: 2,
                link: "/my-applications",
                parentMenuId: null,
                menuName: "My Applications",
                menuCode: null,
              },
              // {
              //   icon: "WorkOutlined",
              //   menuId: 1,
              //   link: null,
              //   parentMenuId: null,
              //   menuName: "Internships",
              //   menuCode: null,
              // },

              {
                icon: "HelpOutline",
                menuId: 4,
                link: null,
                parentMenuId: null,
                menuName: "Grievance",
                menuCode: null,
              },
            ],
            subMenuList: [
              {
                icon: null, //"FormatListNumbered",
                menuId: 44,
                link: "/raise-grievance",
                parentMenuId: 4,
                menuName: "Raise Grievance",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 45,
                link: "/view-grievance-intern",
                parentMenuId: 4,
                menuName: "View Grievance",
                menuCode: null,
              },
              // {
              //   icon: null,
              //   menuId: 101,
              //   link: "/internship-opportunities",
              //   parentMenuId: 1,
              //   menuName: "Find Internship Opportunities",
              //   menuCode: null,
              // },
              // {
              //   icon: null,
              //   menuId: 102,
              //   link: "/my-applications",
              //   parentMenuId: 1,
              //   menuName: "My Applications",
              //   menuCode: null,
              // },
            ],
          };
          break;
        case "ROLE_COMPANY":
          userdetails = {
            menuList: [
              // {
              //   icon: MuiIcons["Dashboard"],
              //   menuName: "Dashboard",
              //   link: "/home",
              // },
              {
                icon: "Dashboard",
                menuId: 1,
                link: "/company-dashboard",
                parentMenuId: null,
                menuName: "Dashboard",
                menuCode: null,
              },
              {
                icon: "Fax",
                menuId: 5,
                link: null,
                parentMenuId: null,
                menuName: "Manage Internship Applications",
                menuCode: null,
              },
              {
                icon: "People",
                menuId: 2,
                link: null,
                parentMenuId: null,
                menuName: "Manage Interns",
                menuCode: null,
              },
              {
                icon: "FormatListBulleted",
                menuId: 6,
                link: "/disbursement",
                parentMenuId: null,
                menuName: "Disbursement List",
                menuCode: null,
              },
              {
                icon: "WorkOutlined",
                menuId: 3,
                link: null,
                parentMenuId: null,
                menuName: "Internship Postings",
                menuCode: null,
              },
              {
                icon: "HelpOutline",
                menuId: 4,
                link: null,
                parentMenuId: null,
                menuName: "Grievance",
                menuCode: null,
              },

              // {
              //   icon: "Inbox",
              //   menuId: 1,
              //   link: "/internship-applications-inbox",
              //   parentMenuId: null,
              //   menuName: "Internship Applications Inbox",
              //   menuCode: null,
              // },
              // {
              //   icon: "FormatListBulleted",
              //   menuId: 2,
              //   link: "/view-internship-applications",
              //   parentMenuId: null,
              //   menuName: "View Internship Applications",
              //   menuCode: null,
              // },
              // {
              //   icon: "FormatListBulleted",
              //   menuId: 3,
              //   link: "/post-internship",
              //   parentMenuId: null,
              //   menuName: "Post Internship",
              //   menuCode: null,
              // },
              // {
              //   icon: "FormatListBulleted",
              //   menuId: 4,
              //   link: "/disbursement",
              //   parentMenuId: null,
              //   menuName: "Disbursement List",
              //   menuCode: null,
              // },
              // {
              //   icon: "FormatListBulleted",
              //   menuId: 5,
              //   link: "/feedback-list",
              //   parentMenuId: null,
              //   menuName: "Provide Feedback",
              //   menuCode: null,
              // },
              // {
              //   icon: "FormatListNumbered",
              //   menuId: 3,
              //   link: "/view-postings",
              //   parentMenuId: null,
              //   menuName: "View Postings",
              //   menuCode: null,
              // },
            ],
            subMenuList: [
              {
                icon: null, //"Inbox",
                menuId: 11,
                link: "/internship-applications-inbox",
                parentMenuId: 5,
                menuName: "Internship Applications Inbox",
                menuCode: null,
              },
              {
                icon: null, //"FormatListBulleted",
                menuId: 12,
                link: "/view-internship-applications",
                parentMenuId: 5,
                menuName: "View Internship Applications",
                menuCode: null,
              },
              {
                icon: null, //"FormatListBulleted",
                menuId: 21,
                link: "/feedback-list",
                parentMenuId: 2,
                menuName: "Provide Feedback",
                menuCode: null,
              },
              {
                icon: null, //"FormatListBulleted",
                menuId: 31,
                link: "/post-internship",
                parentMenuId: 3,
                menuName: "Post Internship",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 32,
                link: "/view-postings",
                parentMenuId: 3,
                menuName: "View Postings",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 42,
                link: "/company/grievance-inbox",
                parentMenuId: 4,
                menuName: "Grievance Inbox",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 43,
                link: "/company/view-grievance",
                parentMenuId: 4,
                menuName: "View Grievance",
                menuCode: null,
              },

            ],
          };
          // userdetails = {
          //   menuList: [
          //     {
          //       icon: MuiIcons["Dashboard"],
          //       menuName: "Dashboard",
          //       link: "/home",
          //     },
          //     {
          //       icon: "WorkOutlined",
          //       menuId: 3,
          //       link: null,
          //       parentMenuId: null,
          //       menuName: "Hire",
          //       menuCode: null,
          //     },
          //   ],
          //   subMenuList: [
          //     {
          //       icon: null,
          //       menuId: 301,
          //       link: "/internship-applications-inbox",
          //       parentMenuId: 3,
          //       menuName: "Inbox",
          //       menuCode: null,
          //     },
          //     {
          //       icon: null,
          //       menuId: 302,
          //       link: "/view-internship-applications",
          //       parentMenuId: 3,
          //       menuName: "Manage Applications",
          //       menuCode: null,
          //     },
          //   ],
          // };
          break;
        case "ROLE_INSTITUTE":
          userdetails = {
            menuList: [
              // {
              //   icon: MuiIcons["Dashboard"],
              //   menuName: "Dashboard",
              //   link: "/home",
              // },
              {
                icon: "Dashboard",
                menuId: 1,
                link: "/student-dashboard",
                parentMenuId: null,
                menuName: "Dashboard",
                menuCode: null,
              },
              {
                icon: "WorkOutlined",
                menuId: 2,
                link: "/view-internships",
                parentMenuId: null,
                menuName: "View Internships",
                menuCode: null,
              },
            ],
            subMenuList: [
              // {
              //   icon: null,
              //   menuId: 201,
              //   link: "/onboard-team-members",
              //   parentMenuId: 2,
              //   menuName: "Onboard Team Members",
              //   menuCode: null,
              // },
            ],
          };
          break;
        case "ROLE_GOVERNMENT":
          userdetails = {
            menuList: [
              {
                icon: "Dashboard",
                menuId: 1,
               // link: "/student-dashboard",
                parentMenuId: null,
                menuName: "Dashboard",
                menuCode: null,
              },
              {
                icon: "FormatListBulleted",
                menuId: 5,
                link: "/disbursement",
                parentMenuId: null,
                menuName: "Disbursement List",
                menuCode: null,
              },
              {
                icon: "WorkOutlined",
                menuId: 2,
                link: "/view-internships",
                parentMenuId: null,
                menuName: "View Internships",
                menuCode: null,
              },
              {
                icon: "HelpOutline",
                menuId: 4,
                link: null,
                parentMenuId: null,
                menuName: "Grievance",
                menuCode: null,
              },
            ],
            subMenuList: [

              {
                icon: null, //"FormatListNumbered",
                menuId: 46,
                link: "/government/grievance-inbox",
                parentMenuId: 4,
                menuName: "Grievance Inbox",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 47,
                link: "/government/view-grievance",
                parentMenuId: 4,
                menuName: "View Grievance",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 49,
                link: "/student-dashboard",
                parentMenuId: 1,
                menuName: "Student Dashboard",
                menuCode: null,
              },
              {
                icon: null, //"FormatListNumbered",
                menuId: 50,
                link: "/company-dashboard",
                parentMenuId: 1,
                menuName: "Company Dashboard",
                menuCode: null,
              },
              // {
              //   icon: null,
              //   menuId: 201,
              //   link: "/onboard-team-members",
              //   parentMenuId: 2,
              //   menuName: "Onboard Team Members",
              //   menuCode: null,
              // },
            ],
          };
          break;
        default:
          navigate("/student-dashboard");
          showSnackbar("User role not found", "info");
          return;
      }
    } else {
      navigate("/student-dashboard");
      showSnackbar("Opps, something went wrong", "error");
    }

    // const userdetails = {
    //   menuList: [
    //     {
    //       icon: "WorkOutlined",
    //       menuId: 1,
    //       link: null,
    //       parentMenuId: null,
    //       menuName: "Internships",
    //       menuCode: null,
    //     },
    //     {
    //       icon: "WorkOutlined",
    //       menuId: 2,
    //       link: null,
    //       parentMenuId: null,
    //       menuName: "Manage Students",
    //       menuCode: null,
    //     },
    //     {
    //       icon: "WorkOutlined",
    //       menuId: 3,
    //       link: null,
    //       parentMenuId: null,
    //       menuName: "Hire",
    //       menuCode: null,
    //     },
    //   ],
    //   subMenuList: [
    //     {
    //       icon: null,
    //       menuId: 101,
    //       link: "/internship-opportunities",
    //       parentMenuId: 1,
    //       menuName: "Find Internship Opportunities",
    //       menuCode: null,
    //     },
    //     {
    //       icon: null,
    //       menuId: 102,
    //       link: "/my-applications",
    //       parentMenuId: 1,
    //       menuName: "My Applications",
    //       menuCode: null,
    //     },
    //     {
    //       icon: null,
    //       menuId: 201,
    //       link: "/onboard-team-members",
    //       parentMenuId: 2,
    //       menuName: "Onboard Team Members",
    //       menuCode: null,
    //     },
    //     {
    //       icon: null,
    //       menuId: 301,
    //       link: "/internship-applications-inbox",
    //       parentMenuId: 3,
    //       menuName: "Inbox",
    //       menuCode: null,
    //     },
    //     {
    //       icon: null,
    //       menuId: 302,
    //       link: "/view-internship-applications",
    //       parentMenuId: 3,
    //       menuName: "Manage Applications",
    //       menuCode: null,
    //     },
    //   ],
    // };

    let i;
    for (i = 0; i < Object.keys(userdetails.menuList).length; i++) {
      myObject.push({
        icon: MuiIcons[userdetails.menuList[i].icon],
        menuName: userdetails.menuList[i].menuName,
        link: userdetails.menuList[i].link,
        items: userdetails.subMenuList.filter(
          (x) => x.parentMenuId === userdetails.menuList[i].menuId
        ),
      });
    }

    // } else {
    //   navigate("/");
    // }
    console.log(myObject);
    setMenu(myObject);
  };
  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setSelectedChild(null);
  };

  console.log(menu);
  return menu?.map((item, key) => (
    <Box key={key}>
      <MenuItem
        item={item}
        selected={item === selectedItem}
        onSelect={handleSelectItem}
        selectedChild={selectedChild}
        setSelectedChild={setSelectedChild}
      />
    </Box>
  ));
}

const MenuItem = ({
  item,
  selected,
  onSelect,
  selectedChild,
  setSelectedChild,
}) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return (
    <Component
      item={item}
      selected={selected}
      onSelect={onSelect}
      selectedChild={selectedChild}
      setSelectedChild={setSelectedChild}
    />
  );
};

const SingleLevel = ({ item, selected, onSelect }) => {
  console.log(item);
  return (
    <Link
      to={item.link}
      style={{
        textDecoration: "none",
        display: "block",
        color: selected ? "#2169b3" : "#666666",
      }}
    >
      <ListItem
        button
        onClick={() => onSelect(item)}
        style={{ backgroundColor: selected ? "#f2f9ff" : "inherit" }}
      >
        <ListItemIcon style={{ color: selected ? "#2169b3" : "#666666" }}>
          {item.icon ? <item.icon /> : null}
        </ListItemIcon>
        <Link
          to={item.link}
          style={{
            textDecoration: "none",
            display: "block",
            color: selected ? "#2169b3" : "#666666",
          }}
        >
          <ListItemText primary={item.menuName} />
        </Link>
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({
  item,
  selected,
  onSelect,
  selectedChild,
  setSelectedChild,
}) => {
  const navigate = useNavigate();
  console.log(item);
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleEvent = () => {
    handleToggle();
    onSelect(item);
  };
  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleEvent}
        style={{ backgroundColor: selected ? "#f2f9ff" : "inherit" }}
      >
        <ListItemIcon style={{ color: selected ? "#2169b3" : "#666666" }}>
          {item.icon ? <item.icon /> : null}
        </ListItemIcon>
        <ListItemText
          primary={item.menuName}
          style={{
            color: selected ? "#2169b3" : "#666666",
            fontFamily: "Lato,serif",
          }}
        />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <Link
              key={child.menuId}
              onClick={() => setSelectedChild(child)}
              to={child.link}
              style={{
                textDecoration: "none",
                display: "block",
                color: selectedChild === child ? "#2169b3" : "inherit",
              }}
            >
              <MenuItem
                key={key}
                item={child}
                href={child.link}
                component={child.link}
                selected={child === selectedChild}
                onSelect={setSelectedChild}
                onClick={(child) => child.link}
              />
            </Link>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
