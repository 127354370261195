export const PORTAL_NAMES={
   PORTAL_NAME:"Internship Portal"
}

export const MAX_APPLICATIONS=3; //Per student

export const HEADINGS = {

};

export const APPLICATION_STATUS = {
  APPLICATION_SUBMITTED: {
    ID: 1,
    NAME: "Application Submitted",
  },
  APPLICATION_APPROVED: {
    ID: 2,
    NAME: "Application Approved",
  },
  APPLICATION_REJECTED: {
    ID: 3,
    NAME: "Application Rejected",
  },
  ONGOING_INTERNSHIP: {
    ID: 4,
    NAME: "Ongoing Internship",
  },
  INTERNSHIP_COMPLETED: {
    ID: 5,
    NAME: "Internship Completed",
  },
  VERIFICATION_PENDING: {
    ID: 6,
    NAME: "Pending for Verification",
  },
};