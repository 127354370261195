import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./index.css";
import Header from "./layouts/Header";
import { store } from "./store";
import { useSelector } from "react-redux";
import ViewCertificates from "./pages/applicant-screens/ViewCertificates";

/*****Pages******/
const Login = lazy(() => import("./pages/auth/Login"));
const Home = lazy(() => import("./pages/Home"));
//Applicant Screens:
const StudentDashboard = lazy(() =>
  import("./pages/applicant-screens/StudentDashboard")
);
const StudentProfile = lazy(() =>
  import("./pages/applicant-screens/StudentProfile")
);
// const StudentVerifyAadhar = lazy(() =>
//   import("./pages/applicant-screens/StudentVerifyAadhar")
// );

const StudentCreateAccount = lazy(() =>
  import("./pages/applicant-screens/StudentCreateAccount")
);
const FindJobOpportunities = lazy(() =>
  import("./pages/applicant-screens/FindJobOpportunities")
);
const ApplyForJob = lazy(() => import("./pages/applicant-screens/ApplyForJob"));
const MyApplications = lazy(() =>
  import("./pages/applicant-screens/MyApplications")
);
const ApplicationDetails = lazy(() =>
  import("./pages/applicant-screens/ApplicationDetails")
);
const ApplicationDetailsViewStudent = lazy(() =>
  import("./pages/applicant-screens/ApplicationDetailsViewStudent")
);
const UploadInternship = lazy(() =>
  import("./pages/applicant-screens/UploadInternship")
);
const AddProfileDataPage = lazy(() =>
  import("./pages/applicant-screens/AddProfileDataPage")
);
//Institute Screens:
const OnboardTeamMembers = lazy(() =>
  import("./pages/institute-screens/OnboardTeamMembers")
);
const ManageStudents = lazy(() =>
  import("./pages/institute-screens/ManageStudents")
);
const StudentViewForInstitute = lazy(() =>
  import("./pages/institute-screens/StudentViewForInstitute")
);
const ApplicationViewForInstitute = lazy(() =>
  import("./pages/institute-screens/ApplicationViewForInstitute")
);
//Employer Screens:
const CompanyDashboard = lazy(() =>
  import("./pages/employer-screens/EmployerDashboard")
);
const JobApplicationsInbox = lazy(() =>
  import("./pages/employer-screens/JobApplicationsInbox")
);
const ManageJobApplications = lazy(() =>
  import("./pages/employer-screens/ManageJobApplications")
);

const ManageJobApplication = lazy(() =>
  import("./pages/employer-screens/ManageJobApplication")
);

const EmployerVerification = lazy(() =>
  import("./pages/employer-screens/EmployerVerification")
);

const EmployerCreateAccount = lazy(() =>
  import("./pages/employer-screens/EmployerCreateAccount")
);
const PostInternship = lazy(() =>
  import("./pages/employer-screens/PostInternship")
);
const Disbursement = lazy(() =>
  import("./pages/employer-screens/DisbursementListPage")
);
const FeedbackListPage = lazy(() =>
  import("./pages/employer-screens/FeedbackListPage")
);

const EmployerOrganizationDetails = lazy(() =>
  import("./pages/employer-screens/EmployerOrganizationDetails")
);

const Certificate = lazy(() => import("./pages/applicant-screens/Certificate"));

const ManagePostings = lazy(() =>
  import("./pages/employer-screens/ManagePostings")
);

const EmployerOpportunityView = lazy(() =>
  import("./pages/employer-screens/EmployerOpportunityView")
);
// Grievance Pages
const RaiseGrievance = lazy(() =>
  import("./pages/applicant-screens/RaiseGrievancePage")
);
const ViewGrievancePage = lazy(() =>
  import("./pages/applicant-screens/ViewGrievancePage")
);
const DetailedGrievancePage = lazy(() =>
  import("./pages/employer-screens/DetailedGrievancePage")
);
const GrievanceCompanyInbox = lazy(() =>
  import("./pages/employer-screens/CompanyGrievancePage")
);
const GrievanceGovernmnetInbox = lazy(() =>
  import("./pages/employer-screens/GovernmentGrievancePage")
);
const ViewCompanyGrievance = lazy(() =>
  import("./pages/employer-screens/ViewCompanyGrievancePage")
);
const ViewGovernmentGrievance = lazy(() =>
  import("./pages/employer-screens/ViewGovernmentGrievancePage")
);

//

const App = () => {
  return <AppRoutes />;
};

const AppRoutes = () => {
  // const studentId = store.getState()?.user?.data?.data?.studentId;
  const studentId = useSelector((state) => state?.user?.data?.data?.studentId);
  return (
    <Suspense fallback={<div />}>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} exact />
        {/* <Route path="/studentVerifyAadhar" element={<StudentVerifyAadhar />} /> */}

        <Route
          path="/studentCreateAccount"
          element={<StudentCreateAccount />}
        />
        <Route
          path="/employerCreateAccount"
          element={<EmployerCreateAccount />}
        />
        <Route
          path="/employerOrganizationDetails"
          element={<EmployerOrganizationDetails />}
        />
        {/* <Route path="/" element={<Home />} /> */}
        <Route element={<Header />}>
          <Route path="/home" element={<Home />} />

          {/*Applicant Routes: */}
          <Route path="/student-dashboard" element={<StudentDashboard />} />
          <Route
            path="/student-profile"
            element={<StudentProfile studentId={studentId} />}
          />
          <Route
            path="/profile-data"
            element={<AddProfileDataPage studentId={studentId} />}
          />

<Route
            path="/view-certificates"
            element={<ViewCertificates />}
          />

          <Route
            path="/internship-opportunities"
            element={<FindJobOpportunities />}
          />
          <Route
            path="/apply-for-internship/:internshipId/:providerId"
            element={<ApplyForJob />}
          />
          <Route path="/my-applications" element={<MyApplications />} />
          <Route
            path="/application-details/:applicationId"
            element={<ApplicationDetailsViewStudent />}
          />
          <Route path="/upload-internship" element={<UploadInternship />} />

          {/* Institute Routes: */}
          <Route
            path="/onboard-team-members"
            element={<OnboardTeamMembers />}
          />
          <Route path="/view-internships" element={<ManageStudents />} />
          <Route
            path="/student-view-for-institute/:applicationId"
            element={<StudentViewForInstitute />}
          />
          <Route
            path="/application-view-for-institute/:applicationId"
            element={<ApplicationViewForInstitute />}
          />
          <Route
            path="/certification/:applicationId"
            element={<Certificate />}
          />

          {/* Employer Routes: */}
          <Route path="/company-dashboard" element={<CompanyDashboard />} />
          <Route
            path="/internship-applications-inbox"
            element={<JobApplicationsInbox type="inbox" />}
          />
          <Route
            path="/view-internship-applications"
            element={<JobApplicationsInbox type="view" />}
          />
          <Route
            path="/manage-internship-application/:applicationId"
            element={<ManageJobApplication />}
          />
          {/* <Route
            path="/employer-verification"
            element={<EmployerVerification />}
          /> */}
          <Route path="/post-internship" element={<PostInternship />} />
          <Route path="/feedback-list" element={<FeedbackListPage />} />
          <Route path="/view-postings" element={<ManagePostings />} />
          <Route
            path="/intership-details-view/:internshipId/:providerId"
            element={<EmployerOpportunityView />}
          />
          <Route path="/raise-grievance" element={<RaiseGrievance />} />
          <Route
            path="/view-grievance-intern"
            element={<ViewGrievancePage />}
          />

          {/* Grievance Routes for Companies */}
          <Route
            path="/company/grievance-inbox"
            element={<GrievanceCompanyInbox />}
          />
          <Route
            path="/government/grievance-inbox"
            element={<GrievanceGovernmnetInbox />}
          />
          <Route
            path="/view-grievance/:grievanceId"
            element={<DetailedGrievancePage />}
          />

          {/* Grievance Routes for Government */}
          <Route
            path="/company/view-grievance"
            element={<ViewCompanyGrievance />}
          />
          <Route
            path="/government/view-grievance"
            element={<ViewGovernmentGrievance />}
          />

          {/* Government Routes: */}
          <Route path="/disbursement" element={<Disbursement />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
// function RequireAuth({ children }) {
//   const user = useSelector((state) => state.user);
//   return user.data.length !== 0 ? children : <Navigate to="/" replace />;
// }

export default App;
