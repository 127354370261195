import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
  SUCCESS: "success",
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    status: STATUSES.IDLE,
  },
  reducers: {
    logout: (state, action) => {
      console.log("1)Logout Action Triggered", state);
      state.data = [];
      console.log("2)Logout Action Triggered", state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setUsers, setStatus, logout } = userSlice.actions;
// Thunks
export const fetchUsers = createAsyncThunk("users/fetch", async (body) => {
  const res = await axios.post(
    `${process.env.REACT_APP_HOST_API_KEY}/login`,
    body
  );
  return res;
});
export default userSlice.reducer;
